import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
// import { reportWebVitals, sendToGoogleAnalytics } from './reportWebVitals';
import Portfolio from './root/Portfolio';

const appContainer = document.getElementById('root')!;
const root = createRoot(appContainer);
root.render(<Portfolio />);

// Setup page metric handler
// reportWebVitals(sendToGoogleAnalytics);
